<template>
  <div>
    <div class="secondary-nav-btn" @click="openMenu = !openMenu" :class="{openMenu: openMenu}">
      <div class="subsection-tilte">
        {{ subsectionTitle }}
      </div>
      <div class="subsection-icon" :class="{openMenu: openMenu}">
        <span></span>
        <span></span>
      </div>
    </div>
    <div id="secondary-nav" :class="{openMenu: openMenu}">
      <div class="s-nav-links" v-if="selectedRootNav && selectedRootNav.subsections.length > 0" :class="{openMenu: openMenu}">
        <div
          v-for="(subsection, index) in selectedRootNav.subsections"
          :key="'s-nav' + index"
          :class="`s-nav-link ${activeSubsectionId === 's-nav'+index ? 'active' : ''}`"
        >
          <router-link
            v-if="subsection.subsections && subsection.subsections[0] && !subsection.ignoreRouteSkip"
            :to="selectedRootNav.to + subsection.subroute + subsection.subsections[0].subroute"
            class="sideBtn-main"
          >{{subsection.title}}</router-link>
          <router-link
            v-else
            @click="subsectionClicked('s-nav' + index, subsection)"
            :to="selectedRootNav.to + subsection.subroute"
            class="sideBtn-main"
          >{{subsection.title}}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SideBarNavMobile",
  props: {
    subsectionTitle: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(["epitch", "global"])
  },
  watch: {
    $route: function(newRoute, oldRoute) {
      if (!newRoute) return;
      this.handleRoute(newRoute.path);
    },
    selectedRootNav: function(newRootNav, oldRootNav) {
      if (!newRootNav) return;
      //window.console.log(newRootNav)
      if (newRootNav.subsections.length > 0) {
        document.getElementById('secondary-nav').classList.add('visible');
      } else {
        document.getElementById('secondary-nav').classList.remove('visible');
      }
      
    }
  },
  data() {
    return {
      selectedRootNav: null,
      selectedSubNav: null,
      activeSubsectionId: "",
      openMenu: false
    };
  },
  mounted() {
    this.handleRoute(this.$route.path);
  },
  methods: {
    handleRoute(pathname) {
      if (
        !this.epitch ||
        !this.epitch.sections ||
        !this.epitch.sections.length ||
        this.epitch.sections.length < 1
      ) {
        return;
      }
      const epitchSections = this.epitch.sections;

      let paths = pathname.split("/");
      if (!paths) return;
      paths = paths.filter(path => path && path != "").map(path => "/" + path);

      let selectedRootNav = null;
      if (paths.length >= 1) {
        selectedRootNav = epitchSections.find(
          epitchSection => epitchSection.to === paths[0]
        );
        this.selectedRootNav = selectedRootNav;
      }
      if (paths.length > 1) {
        this.selectedSubNav = selectedRootNav.subsections.find(
          epitchSection => epitchSection.subroute === paths[1]
        );
      }
    },
    subsectionClicked(subsectionId, subsection) {
      this.activeSubsectionId = subsectionId;
    }
  }
};
</script>

<style lang="scss" scoped>
.secondary-nav-btn {
  background-image: linear-gradient(223deg, #000000 0%, #252525 70%);
  position: fixed;
  width: 100%;
  height: 60px;
  top: 75px;
  z-index: 3;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  &.openMenu {
     background-image: none;
  }
  .subsection-tilte {
    font-family: 'ArtegraSans-Bold';
    font-size: 12px;
    color: #CACACA;
    letter-spacing: 0.67px;
    line-height: 10px;
    text-transform: uppercase;
    position: absolute;
    bottom: 18px;
    left: 10px;
  }
  .subsection-icon {
    position: absolute;
    bottom: 25px;
    right: 23px;
    span {
      display: block;
      position: absolute;
      height: 2px;
      background: #d8d8d8;
      border-radius: 9px;
      opacity: 1;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        transform: rotate(45deg);
        left: -6px;
        width: 10px;
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
        width: 10px;
        left: 0px;
      }
    }
    &.openMenu {
      span {
        &:nth-child(1) {
          left: -3px;
        }
        &:nth-child(2) {
          left: -3px;
        }
      }
    }
  }
}
#secondary-nav {
  position: fixed;
  top: 0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 0vh;
  background: url('~@/assets/images/Landing/menu-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.25s ease-in-out;
  z-index: 2;
  &.openMenu {
    opacity: 1;
    visibility: visible;
    height: 100vh;
  }
  .s-nav-links {
    display: none;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    &.openMenu {
      display: flex;
    }
    .s-nav-link {
      cursor: pointer;
      text-align: center;
      padding: 0 30px;
      margin-bottom: 3px;
      .sideBtn-main {
        padding: 10px 0;
        font-size: 13px;
        font-family: 'ArtegraSans-Bold';
        line-height: 14px;
        letter-spacing: 0.81px;
        text-decoration: none;
        text-align: left;
        text-transform: uppercase;
        color: #9D9D9D;
        &.is-active {
          color: #ffffff;
        }
      }
    }
    .expanded-subsections {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 15px 0;
      .e-nav-link {
        padding-left: 15px;
        .sideBtn-sub {
          font-size: 11px;
          font-family: 'ArtegraSans-Bold';
          line-height: 16px;
          letter-spacing: 0.81px;
          text-decoration: none;
          text-align: left;
          text-transform: uppercase;
          color: #9D9D9D;
          &.is-active {
            text-decoration: none;
            color: #FFFFFF;
            text-shadow: 0 0 10px rgba(255,255,255,0.50);
          }
        }
      }
    }
  }
}
</style>